import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { ActivityService, UserService } from '#services/api';
import * as moment from 'moment';
import { forkJoin, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { LoadingIndicatorService } from '#services/shared';

@Component({
    selector: 'app-activity-audit-log-filter',
    templateUrl: './activity.audit.log.filter.component.html',
    styleUrls: ['./activity.audit.log.filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ActivityAuditLogFilterComponent implements OnInit, OnDestroy {

  @Input()
  preFilters;

  activities;
  selectedActivity;
  filteredActivities = [];
  readonly activitiySuggestionCount: number = 5;

  selectedStartDate;
  selectedEndDate;

  users;
  selectedUser;

  @Output()
  search = new EventEmitter<any>();

  private subscriptions: Subscription[] = [];

  constructor(
    private activityService: ActivityService,
    private userService: UserService,
    private loadingIndicatorSvc: LoadingIndicatorService
    ) {
  }

  ngOnInit() {
    const tasks = [];
    tasks.push(this.activityService.getActivitiesForProject(this.preFilters.projectObjectId));
    tasks.push(this.userService.getAll());
    const observable = forkJoin(tasks[0],tasks[1])
    .pipe(map(([a, u]) =>({activities: a, users: u})));
    this.loadingIndicatorSvc.show();
    const sub = observable.pipe(
    finalize(() => this.loadingIndicatorSvc.hide()))
    .subscribe({
      next: values => {
        this.activities = values.activities;
        this.activities.forEach(e => e.displayName = `${e.ActivityId} - ${e.Name}`);

        this.selectedActivity = this.preFilters.activityObjectId
          ? this.activities.find(e => e.Id === this.preFilters.activityObjectId)
          : undefined;

        this.users = values.users;

        if(this.preFilters.activityObjectId) {
          this.ExecuteSearch();
        }
      }
    });

    this.subscriptions.push(sub);
  }

  ExecuteSearch() {
    const values = {
      activityObjectId: this.selectedActivity ? this.selectedActivity.Id : null,
      startDate: this.selectedStartDate ? moment(this.selectedStartDate).format() : null,
      endDate: this.selectedEndDate ? moment(this.selectedEndDate).format() : null,
      createdById: this.selectedUser ? this.selectedUser.Id : null
    };

    this.search.emit(values);
  }

  filterActivities(event) {
    if (!event.query) {
      return;
    }

    this.filteredActivities = [];

    const query = event.query.toLowerCase();
    for(let i  in this.activities) {

      if (this.filteredActivities.length > this.activitiySuggestionCount) {
        break;
      }

      const activity = this.activities[i];
      if(activity.ActivityId.toLowerCase().indexOf(query) >= 0
        || activity.Name.toLowerCase().indexOf(query) >= 0) {
          this.filteredActivities.push(activity);
      }
    }
  }

  clearSelectedActivity() : void {
    this.selectedActivity = null;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }
}
